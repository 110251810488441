import { AxiosError } from 'axios';

import { SignInRequest, SignUpRequest, UserTokens } from './types/types.ts';
import { AuthManager, AuthStatus } from './AuthManager.ts';

import { Member } from '../types';
import { getUserInfoFromLocalStorage, KeychainData } from './userInfoKeyChain.ts';
import { ServiceResponse } from '../types/ServiceResponse.ts';
import { createLog } from './authLog';
import { useMemberStore } from '../api/query/member/useMemberStore.ts';
import { publicAPIContainer } from '../api/context';
import { AuthStore } from '../hooks/useAuthStore.ts';

const authLog = createLog('🗝️ AuthManager');

export class AppAuthManager implements AuthManager {
  constructor(
    readonly getStore: () => AuthStore,
    readonly api: {
      verifySession: () => Promise<ServiceResponse<Member>>;
      signUpWithEmailAndPassword: (
        signUpRequest: SignUpRequest
      ) => Promise<ServiceResponse<Member>>;
      reloadToken: (refreshToken: string) => Promise<ServiceResponse<UserTokens>>;
      loginWithEmailAndPassword: (signInRequest: SignInRequest) => Promise<ServiceResponse<Member>>;

      logout: () => Promise<ServiceResponse<Member>>;
    }
  ) {}

  async checkInitialSession(): Promise<
    Extract<AuthStatus, 'AUTHORIZED' | 'UNAUTHORIZED' | 'NO_INFO'>
  > {
    const { setStatus, setUserData, logout } = this.getStore();
    // setupDIContainer();
    const keychainInfo = await getUserInfoFromLocalStorage();

    if (keychainInfo) {
      authLog(`Tokens are existed.', ${keychainInfo}`);

      const token = keychainInfo.accessToken;
      const refreshToken = keychainInfo.refreshToken;
      await setUserData(keychainInfo);

      try {
        /**
         * Check the current user's token validity.
         */

        const loginResult = await publicAPIContainer.auth
          .verifySession()
          .then((response) => response.data);
        if (loginResult.code === 200) {
          /**
           * It is okay, set the status as authorized.
           */
          useMemberStore.setState({ data: loginResult.results });
          authLog(`Login successful, ${token}`);
          return setStatus('AUTHORIZED');
        }
      } catch (e) {
        const error = e as AxiosError;
        const statusCode = error.response?.status;
        authLog('Login check error - ' + error);

        /**
         * User was signed out. Try to renew access token.
         */
        if (statusCode && statusCode >= 400) {
          authLog('Reloading tokens - ');

          return this.reloadTokens(refreshToken);
        }
      }

      /**
       * Finally, logout user if the session is not valid.
       */
      return 'UNAUTHORIZED';
    } else {
      /**
       * If the onboarding completed, and there is no a credentials,
       * return logout and mark as unauthorized.
       */

      /**
       * If it is not completed, try to create an anonymous user.
       */
      return 'NO_INFO';
    }
  }

  async reloadTokens(
    refreshToken: string
  ): Promise<Extract<AuthStatus, 'AUTHORIZED' | 'UNAUTHORIZED'>> {
    const { setStatusAndUserData, reset } = this.getStore();
    try {
      authLog('Refreshing token...');
      const response = await this.api.reloadToken(refreshToken);
      const data = response.results;
      authLog(`Tokens refreshed!, ${data}`);
      const keyStoreData: KeychainData = {
        id: data.refreshToken,
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      };
      return setStatusAndUserData('AUTHORIZED', keyStoreData).then(({ status }) => status);
    } catch (e) {
      authLog(`Tokens are not refreshed!', ${e},${e.data}, ${e.message}`);
      await reset();
      return setStatusAndUserData('UNAUTHORIZED').then(({ status }) => status);
    }
  }

  async signUpWithEmailAndPassword(signUpRequest: SignUpRequest) {
    const { setStatusAndUserData } = this.getStore();

    return this.api.signUpWithEmailAndPassword(signUpRequest).then((res) => {
      if (res.code === 200) {
        const keyChainData = {
          refreshToken: res.results.refreshToken,
          id: res.results.refreshToken,
          accessToken: res.results.accessToken,
        };
        setStatusAndUserData('AUTHORIZED', keyChainData);
        return res;
      }
      return;
    });
  }

  async loginWithEmailAndPassword(signInRequest: SignInRequest) {
    const { setStatusAndUserData } = this.getStore();
    return this.api.loginWithEmailAndPassword(signInRequest).then((res) => {
      if (res.code === 200) {
        const keyChainData = {
          refreshToken: res.results.refreshToken,
          id: res.results.refreshToken,
          accessToken: res.results.accessToken,
        };
        setStatusAndUserData('AUTHORIZED', keyChainData);
        return res;
      }
      return res;
    });
  }

  logout(): Promise<any> {
    const { logout } = this.getStore();
    return this.api.logout().finally(() => {
      return logout().then(() => {
        useMemberStore.setState({ data: undefined });
      });
    });
  }

  getStoredAccessToken(): string | undefined {
    return this.getStore().data?.accessToken;
  }

  getStoredRefreshToken(): string | undefined {
    return this.getStore().data?.refreshToken;
  }
}
