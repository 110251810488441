import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import SuccessMessage from '../../components/Forms/SuccessMessage';

import i18n from '../../i18n/i18n';
import { authManager } from '../../auth/composites/authManager';

const SignoutScreen = () => {
  const navigate = useNavigate();

  const { member } = useMember();

  const [success, setSuccess] = useState(null);

  const signOut = async () => {
    authManager.logout().then((response) => {
      setSuccess({ title: i18n.t('success'), message: response.title, buttonText: i18n.t('ok') });
    });
  };

  useEffect(() => {
    const runAsync = async () => {
      if (member) {
        await signOut(member.accessToken);
      } else {
        navigate('/members/signin');
      }
    };

    runAsync();
  }, []);

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('signout')} />
      <SiteLayout headerType='white' footerType='green'>
        <section className='member-padding member-back'>
          <div className='container'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('signout')}</div>
                  </li>
                </ul>
              </div>
              <div className='transition'>
                <p>{i18n.t('signedOut')}</p>
                <Link className='button button-green button-medium' to={`/${i18n.locale}`}>
                  {i18n.t('goToHome')}
                </Link>
              </div>
            </div>
          </div>
        </section>
        {success && (
          <SuccessMessage
            title={success.title}
            message={success.message}
            buttonText={success.buttonText}
            click={() => setSuccess(null)}
          />
        )}
      </SiteLayout>
    </>
  );
};

export default SignoutScreen;
