import { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { AiOutlineGoogle, AiFillApple } from 'react-icons/ai';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
// eslint-disable-next-line camelcase
import parse from 'html-react-parser';

import useLanguage from '../../hooks/useLanguage';
import { useLocationLocal } from '../../hooks/useLocation';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import ErrorMessage from '../../components/Forms/ErrorMessage';
import FormInput from '../../components/Forms/FormInput';
import FormButton from '../../components/Forms/FormButton';
import RadioButton from '../../components/Forms/RadioButton';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request.ts';

import i18n from '../../i18n/i18n';
import React from 'react';
import { authManager } from '../../auth/composites/authManager.ts';
import { useMemberStore } from '../../api/query/member/useMemberStore.ts';

const useQuery = () => new URLSearchParams(useLocation().search);

const SignupScreen = () => {
  const query = useQuery();
  const { subId: paramSubId } = useParams();

  const navigate = useNavigate();

  const { language } = useLanguage();
  const { location } = useLocationLocal();

  const [tab, setTab] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState(null);
  const [subId, setSubId] = useState(null);
  const [redeemCode, setRedeemCode] = useState(null);
  const [onBoardHTML, setOnBoardHTML] = useState('');
  const [formValues, setFormValues] = useState({
    emailAddress: '',
    password: '',
    name: '',
    lastname: '',
    passwordAgain: '',
    country: 0,
    newsletters: false,
    agreeToPolicies: false,
    agreeToPolicies2: false,
    key: '',
    code: '',
  });
  const { fetch } = useMemberStore();

  useEffect(() => {
    if (paramSubId) {
      setSubId(paramSubId);
    }

    const runAsync = async () => {
      const hashQuery = query.get('hash');
      if (hashQuery) {
        const parameters = {
          method: 'POST',
          url: 'v1/checkHash',
          apiType: 'memberApi',
          data: {
            hash: hashQuery,
          },
          language: language.appLanguage,
        };

        const request = await Request.getResponse(parameters);

        if (request.status === 200) {
          setRedeemCode(request.data.results.code);
        }

        const parametersBoard = {
          method: 'POST',
          url: 'v1/getcompany_onboard',
          apiType: 'memberApi',
          data: {
            hash: hashQuery,
          },
          language: language.appLanguage,
        };

        const requestBoard = await Request.getResponse(parametersBoard);
        if (requestBoard.status === 200) {
          setOnBoardHTML(requestBoard.data.results);
        }
      }
    };
    runAsync();

    const redirectedUrl = query.get('redirect');

    if (redirectedUrl && redirectedUrl.match(/^[a-z0-9-/]*$/)) {
      setDestination(redirectedUrl);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleSigninSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    setLoading(true);
    authManager
      .loginWithEmailAndPassword({
        emailAddress: formValues.emailAddress,
        password: formValues.password,
        remember: true,
      })
      .then(async (request) => {
        if (request.code === 200) {
          const memberDetails = request.results;
          fetch();
          if (subId) {
            const parametersStripe = {
              method: 'GET',
              url: `v1/stripe/checkout/${subId}`,
              apiType: 'purchaseApi',
              headers: [{ AccessToken: memberDetails.token }],
              language: location.appLocation === 'TR' ? 'tr' : 'en',
            };

            const requestStripe = await Request.getResponse(parametersStripe);

            if (requestStripe.status === 200) {
              window.location.href = requestStripe.data.results.sessionUrl;
            }
          } else if (destination) {
            navigate(destination);
          } else if (redeemCode) {
            const parametersRedeem = {
              method: 'POST',
              url: 'v1/purchase/redeem',
              apiType: 'purchaseApi',
              data: {
                code: redeemCode,
              },
              headers: [{ AccessToken: memberDetails.token }],
              language: language.appLanguage,
            };

            const requestRedeem = await Request.getResponse(parametersRedeem);

            if (requestRedeem.status === 200) {
              const hashQuery = query.get('hash');
              const parametersMemberId = {
                method: 'POST',
                url: 'v1/setmember',
                apiType: 'memberApi',
                data: {
                  hash: hashQuery,
                },
                headers: [{ AccessToken: memberDetails.token }],
                language: language.appLanguage,
              };
              const requestMemberId = await Request.getResponse(parametersMemberId);
              if (requestMemberId.status === 200) {
                navigate('/members?rdm=ok');
              } else {
                navigate('/members?error=ok');
              }
            }
          } else {
            navigate('/members');
          }
        } else {
          setLoading(false);
          setError({ message: request.title });
        }
      });
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    if (!formValues?.agreeToPolicies) {
      setError({ message: i18n.t('readAllPolicies') });
    } else if (!formValues?.agreeToPolicies2) {
      setError({ message: i18n.t('readAllPolicies') });
    } else {
      setLoading(true);
      authManager
        .signUpWithEmailAndPassword({
          emailAddress: formValues.emailAddress,
          password: formValues.password,
          name: formValues.name,
          lastname: formValues.lastname,
          passwordAgain: formValues.passwordAgain,
          country: formValues.country,
          newsletters: formValues.newsletters,
          isWebAccess: true,
        })
        .then((response) => {
          if (response?.code === 200) {
            setFormValues({
              ...formValues,
              key: response?.results.id,
            });
            handleSigninSubmit(e);
          } else {
            if (response?.title === 'The email address you entered is already taken.') {
              setError({ message: i18n.t('conflictEmailMsg') });
            } else {
              setError({ message: response?.title });
            }

            setLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setError({ message: e?.response.data.title });
        })
        .finally(() => setLoading(false));
    }
  };

  const handleActivationSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    setLoading(true);

    const parameters = {
      method: 'POST',
      url: 'v1/activation',
      apiType: 'memberApi',
      data: {
        memberId: formValues.key,
        activationCode: formValues.code,
      },
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      navigate('/members');
    } else {
      setLoading(false);

      setError({ message: request.title });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title={i18n.t('signin')}
        description='Sign in to your account.'
        keyword='sign in, sign in to your account, breath hub, breath hub app'
        canonical='https://webapp.breathhub.app/members/signin'
      />
      <SiteLayout headerType='white' footerType='green'>
        {error !== null && error.message !== 'idpiframe_initialization_failed' && (
          <ErrorMessage message={error.message} />
        )}
        <section
          className={
            tab === 0 ? 'gray member-padding member-back' : 'gray member-padding member-back2'
          }
        >
          <div className='container'>
            <div className='row'>
              <div className={onBoardHTML !== '' ? 'col-3' : 'col-6'}>
                <div className='box min-box'>
                  <div className='box-tabs'>
                    <ul>
                      <li className='pad-left'>
                        <div
                          aria-hidden='true'
                          className={tab === 1 || tab === 2 ? 'active' : 'passive'}
                          onClick={() => {
                            setTab(1);
                            setError(null);
                          }}
                        >
                          {i18n.t('signup')}
                        </div>
                      </li>
                    </ul>
                  </div>

                  {tab === 0 && (
                    <div className='transition'>
                      <div className='social-login-separator center'>
                        <span>{i18n.t('or')}</span>
                      </div>

                      <p>{i18n.t('signinDescription')}</p>
                      <form className='form' onSubmit={handleSigninSubmit} noValidate>
                        <div className='form-elements'>
                          <div className='form-line'>
                            <label htmlFor='emailAddress'>{i18n.t('emailLabel')}</label>
                            <FormInput
                              type='email'
                              name='emailAddress'
                              value={formValues.emailAddress}
                              placeholder={i18n.t('emailPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='password'>{i18n.t('passwordLabel')}</label>
                            <FormInput
                              type='password'
                              name='password'
                              value={formValues.password}
                              placeholder={i18n.t('passwordPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line right'>
                            <Link to='/members/forgot-password'>
                              {i18n.t('forgotPasswordLink')}
                            </Link>
                          </div>
                          <div className='form-line'>
                            {/*<div className='buttons'>*/}
                            {/*  <FormButton*/}
                            {/*    type='submit'*/}
                            {/*    text={i18n.t('signin')}*/}
                            {/*    onClick={handleSigninSubmit}*/}
                            {/*  />*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {tab === 1 && (
                    <div className='transition'>
                      <p>{i18n.t('signupDescription')}</p>
                      <form className='form' onSubmit={handleSignupSubmit} noValidate>
                        <div className='form-elements'>
                          <div className='form-line'>
                            <label htmlFor='name'>{i18n.t('nameLabel')}</label>
                            <FormInput
                              type='text'
                              name='name'
                              value={formValues.name}
                              placeholder={i18n.t('namePlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='lastname'>{i18n.t('lastnameLabel')}</label>
                            <FormInput
                              type='text'
                              name='lastname'
                              value={formValues.lastname}
                              placeholder={i18n.t('lastnamePlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='emailAddress'>{i18n.t('emailLabel')}</label>
                            <FormInput
                              type='email'
                              name='emailAddress'
                              value={formValues.emailAddress}
                              placeholder={i18n.t('emailPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='password'>{i18n.t('passwordLabel')}</label>
                            <FormInput
                              type='password'
                              name='password'
                              value={formValues.password}
                              placeholder={i18n.t('passwordPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='passwordAgain'>{i18n.t('passwordConfirmLabel')}</label>
                            <FormInput
                              type='password'
                              name='passwordAgain'
                              value={formValues.passwordAgain}
                              placeholder={i18n.t('passwordConfirmPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <RadioButton
                              name='newsletters'
                              checked={formValues.newsletters}
                              text={i18n.t('marketingPermission')}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className='form-line'>
                            <RadioButton
                              name='agreeToPolicies'
                              checked={formValues.agreeToPolicies}
                              text={i18n.t('permissions1')}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className='form-line'>
                            <RadioButton
                              name='agreeToPolicies2'
                              checked={formValues.agreeToPolicies2}
                              text={i18n.t('permissions2')}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className='form-line'>
                            <div className='buttons'>
                              <FormButton
                                type='submit'
                                text={i18n.t('signup')}
                                onClick={handleSignupSubmit}
                              />
                            </div>
                          </div>
                          <div className='form-line center'>
                            <Link to='/members/signin'>{i18n.t('signin')}</Link>
                          </div>
                          <div className='form-line'>
                            <div className='links'>
                              <Link to={`/${language.appLanguage}/legal/terms-and-conditions`}>
                                {i18n.t('termsAndConditions')}
                              </Link>
                              &bull;
                              <Link to={`/${language.appLanguage}/legal/privacy-policy`}>
                                {i18n.t('privacyPolicy')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {tab === 2 && (
                    <div className='transition'>
                      <p>{i18n.t('activationDescription')}</p>
                      <form className='form' onSubmit={handleActivationSubmit} noValidate>
                        <div className='form-elements'>
                          <div className='form-line'>
                            {/* <label htmlFor='code'>{i18n.t('activationCodeLabel')}</label> */}
                            {/* <FormInput
                              type='text'
                              name='code'
                              value={formValues.code}
                              placeholder={i18n.t('activationCodePlaceholder')}
                              onChange={handleChange}
                            /> */}
                          </div>
                          <div className='form-line'>
                            {/* <div className='buttons'>
                              <FormButton
                                type='submit'
                                text={i18n.t('activateMyAccount')}
                                onClick={handleActivationSubmit}
                              />
                            </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {onBoardHTML !== '' && (
                <div className='col-3'>
                  <div>{parse(onBoardHTML)}</div>
                </div>
              )}
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default SignupScreen;
