import { memo } from 'react';
import useLanguage from '../../hooks/useLanguage';
import PremiumCard from '../Cards/PremiumCard';

// TODO member bu community satın almış ise kontrolünü yap sonra
const Premium = memo(({ data }) => {
  const { language } = useLanguage();
  const getName = (type) => {
    let str = '';
    if (type === 'monthly') {
      if (language.appLanguage === 'en') {
        str = 'Subscribe for 1 Month';
      } else if (language.appLanguage === 'tr') {
        str = '1 Aylık Abonelik';
      }
    } else if (type === 'yearly') {
      if (language.appLanguage === 'en') {
        str = 'Subscribe for 1 Year';
      } else if (language.appLanguage === 'tr') {
        str = '1 Yıllık Abonelik';
      }
    }

    return str;
  };

  const getPriceWithCurrency = (price, currency) => {
    let lang;
    if (currency === 'USD') {
      lang = 'en-US';
    } else if (currency === 'TRY') {
      lang = 'tr-TR';
    } else if (currency === 'GBP') {
      lang = 'en-GB';
    }

    return new Intl.NumberFormat(lang, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(price));
  };

  const getPricing = (type, discountRate, price) => {
    let str = '';

    if (type === 'monthly') {
      if (language.appLanguage === 'en') {
        if (discountRate) {
          str = `Only <strong>${getPriceWithCurrency(
            (parseFloat(price) * (100.0 - discountRate)) / 100.0,
            'TRY'
          )}</strong> instead of <del>${getPriceWithCurrency(price, 'TRY')}</del> per month.`;
        } else {
          str = `<strong>${getPriceWithCurrency(price, 'TRY')}</strong> per month.`;
        }
      } else if (language.appLanguage === 'tr') {
        if (discountRate) {
          str = `Aylık <del>${getPriceWithCurrency(
            price,
            'TRY'
          )}</del> yerine sadece <strong>${getPriceWithCurrency(
            (parseFloat(price) * (100.0 - discountRate)) / 100.0,
            'TRY'
          )}</strong>.`;
        } else {
          str = `Aylık <strong>${getPriceWithCurrency(price, 'TRY')}</strong>.`;
        }
      }
    } else if (type === 'yearly') {
      if (language.appLanguage === 'en') {
        if (discountRate) {
          str = `Only <strong>${getPriceWithCurrency(
            ((parseFloat(price) / 12.0) * (100.0 - discountRate)) / 100.0,
            'TRY'
          )}</strong> instead of <del>${getPriceWithCurrency(
            parseFloat(price) / 12.0,
            'TRY'
          )}</del> per month. Billed at <strong>${getPriceWithCurrency(
            (parseFloat(price) * (100.0 - discountRate)) / 100.0,
            'TRY'
          )}</strong> instead of <del>${getPriceWithCurrency(price, 'TRY')}</del>`;
        } else {
          str = `<strong>${getPriceWithCurrency(
            parseFloat(price) / 12.0,
            'TRY'
          )}</strong> per month. Billed at <strong>${getPriceWithCurrency(price, 'TRY')}</strong>`;
        }
      } else if (language.appLanguage === 'tr') {
        if (discountRate) {
          str = `Aylık <del>${getPriceWithCurrency(
            parseFloat(price) / 12.0,
            'TRY'
          )}</del> yerine sadece <strong>${getPriceWithCurrency(
            ((parseFloat(price) / 12.0) * (100.0 - discountRate)) / 100.0,
            'TRY'
          )}</strong>. Toplam <del>${getPriceWithCurrency(
            price,
            'TRY'
          )}</del> yerine sadece <strong>${getPriceWithCurrency(
            (parseFloat(price) * (100.0 - discountRate)) / 100.0,
            'TRY'
          )}</strong>`;
        } else {
          str = `Aylık <strong>${getPriceWithCurrency(
            parseFloat(price) / 12.0,
            'TRY'
          )}</strong>. Toplam <strong>${getPriceWithCurrency(price, 'TRY')}</strong>`;
        }
      }
    }

    return str;
  };

  const getDescription = (type) => {
    let str = '';
    const discountRateMonthly = parseFloat(data?.discountRateForMonthly);
    const discountRateYearly = parseFloat(data?.discountRateForYearly);

    if (type === 'monthly' && discountRateMonthly) {
      if (language.appLanguage === 'en') {
        str = `%${discountRateMonthly.toFixed(0)} discount`;
      } else if (language.appLanguage === 'tr') {
        str = `%${discountRateMonthly.toFixed(0)} indirim`;
      }
    } else if (type === 'yearly' && discountRateYearly) {
      if (language.appLanguage === 'en') {
        str = `%${discountRateYearly.toFixed(0)} discount`;
      } else if (language.appLanguage === 'tr') {
        str = `%${discountRateYearly.toFixed(0)} indirim`;
      }
    }

    return str;
  };

  return (
    <section id='premium' className='white'>
      <div
        className='hero premium-hero'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${data?.content?.backgroundPicture}')`,
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-6'>
              <div className='section-title'>
                <h3>
                  <strong>{data?.content?.title}</strong>
                </h3>
                <p>{data?.content?.description}</p>
              </div>
            </div>
          </div>

          <div className='pricing row flex flex-row flex-center'>
            {data &&
              data.prices &&
              Array.isArray(data.prices) &&
              data.prices.reverse().map((item, i) => (
                <div key={item.subscriptionId} className='col-2'>
                  <PremiumCard
                    subId={item?.subscriptionId}
                    path={item?.path}
                    period={item?.period}
                    name={getName(item?.period)}
                    pricing={getPricing(
                      item?.period,
                      parseFloat(item?.stripeDiscountRate),
                      parseFloat(item?.stripePrice)
                    )}
                    description={getDescription(item?.period)}
                    location='TR'
                    transparent={i % 2 === 0}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
});

export default Premium;
